<template>
  <div id="app">
<!--    <label>-->
<!--      切换为横向-->
<!--      <input type="checkbox" v-model="landscape" value="1">-->
<!--    </label>-->
<!--    <div style="display: flex;justify-content: center;align-items: center;padding-top: 60px;">-->
<!--      <TreeChart :json="data" :class="{landscape: landscape.length}" @click-node="clickNode" @click-edit="edit"/>-->
<!--    </div>-->

    <div class="buttonGroup123" style="text-align: left;">
      <el-button type="primary" plain @click="todo">家族来源</el-button>
      <span style="font-weight: 700;padding: 0 10px;">操作说明</span>
      <span>点击：查看、修改信息, </span>
      <span>双击：删除、新增成员. </span>

      <el-dialog :visible.sync="infoVisible" top='0' title="家族来源">
        <div>wqewqeqeas</div>
        <div class="show-info-facce">
<!--           真正展示的内容-->
        </div>

        <!-- 执行动画的图片 -->
        <div class="animation-face">
          <!--        <el-image v-if="showInfo.img" :src="showInfo.img" :fit="'contain'" class="show-img" />-->
        </div>
      </el-dialog>

    </div>
    <TreeChart :json="data" :class="{landscape: landscape.length}" @click-node="clickNode" @click-edit="edit"/>
    <el-dialog title="提示" :visible.sync="outerVisible" width="360px">

      <el-dialog
          width="30%"
          title="新增家庭成员"
          :visible.sync="innerVisible"
          append-to-body>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="radio" @change="change">
              <el-radio :label="1">儿子</el-radio>
              <el-radio :label="2">女儿</el-radio>
              <el-radio :label="3">配偶</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="描述">
            <el-input type="textarea" v-model="form.info"></el-input>
          </el-form-item>
          <el-form-item label="地址">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="电话">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="基本信息对谁公开">
            <el-input v-model="form.showUserid"></el-input>
          </el-form-item>
        </el-form>
          <div slot="footer" class="dialog-footer">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即创建</el-button>
              <el-button>取消</el-button>
            </el-form-item>
            <el-button @click="innerVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">保 存</el-button>
          </div>

      </el-dialog>
      <div style="font-size: 18px;">你正在对对 <span style="color: #e56c3c; font-weight: 700;">{{currentNode.name}}</span> 节点进行操作，可以新增家庭成员和删除家庭成员。</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="danger" @click="delNode">删除成员</el-button>
        <el-button type="primary" @click="innerVisible = true,outerVisible = false">新增成员</el-button>
      </div>
    </el-dialog>
<!--    <el-dialog-->
<!--        title="编辑"-->
<!--        :visible.sync="editDialogVisible"-->
<!--        width="30%"-->
<!--        :before-close="handleClose">-->
<!--      <span>这是一段信息</span>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="editDialogVisible = false">取 消</el-button>-->
<!--    <el-button type="primary" @click="editDialogVisible = false">保 存</el-button>-->
<!--  </span>-->
<!--    </el-dialog>-->


  </div>
</template>

<script>
import TreeChart from "@/components/TreeChart";
export default {
  name: 'tree',
  components: {
    TreeChart
  },
  data() {
    return {
      infoBox: '',
      outerVisible: false,
      innerVisible: false,
      editDialogVisible: false,
      infoVisible: false,
      currentNode: {},
      currentPersion: {},
      landscape: [],
      jsonData: [],
      ////
      data: {},
      form: {},
      radio: 1,
      editForm: {},
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    change(value){
      this.radio = value
    },
    load(){
      this.request.get("/wang-user").then(res => {
        if(res.code === '200'){
          this.jsonData = res.data
          console.log("原始数据：", res.data)
          this.data = this.dataList(res.data)
          console.log("原始数据：", this.data)
        }
      })
//     console.log("扁平化转换成树形结构：");
// console.log("原数组结构",this.jsonData);
// console.log("树形结构",this.dataList(this.jsonData));
// this.data = this.dataList(this.jsonData)
//     console.log("树形结构data",this.data);
    },
    todo(){
      this.infoVisible =true

    },
    dataList(data){   // 扁平化转换成树形结构!
      if(this.jsonData.length === 1){
        return this.jsonData[0]
      }
      let map = {};
      for(let item of data){
        map[item.id] = item
      }
      let result = []; //存放数组
      for(let item of data){
        item.children = []; //给每个数组添加一个空children
        item.mate = [];
        if(item.pid === -1){
          result.push(item)//最上级的标签
        }else if(item.sex === '配偶') {
          let parent = map[item.pid]
          parent.mate.push(item)
        }else{
          //相当于用这个 pid 当做父Id去查找对比查找数据
          let parent = map[item.pid]
          //添加到刚刚定义children数组中去
          parent.children.push(item)
        }
      }
      console.log("res",result[0])
      return result[0];
    },

    clickNode: function(node){
      console.log(node)
      this.currentNode = node
      this.outerVisible = true
      for(let item of this.jsonData){ //
        if(item.id === node.id){
          this.currentPersion = item
        }
      }

      // for(let key in this.currentPersion) {
      //   this.form[key] = this.currentPersion[key];
      //   this.form[key] = ''
      // }
      this.form.pid = node.id
      this.form.levelId = node.levelId + 1
      // this.form.name = ''
    },
    save(){
      if(this.radio === 1) {
        this.form.sex = '儿子'
      }else if(this.radio === 2) {
        this.form.sex = '女儿'
      }else {
        this.form.sex = '配偶'
      }
      if(this.form.name !=='' && this.form.name !== undefined){
        this.request.post("/wang-user", this.form).then(res => {
          if(res.code === '200'){
            this.load()
            // console.log(res.data)
            this.innerVisible = false
          }
        })
      }else{
        this.$alert('请输入节点名！', '警告', {
          confirmButtonText: '确定',
        });
      }

    },
    edit(data){
      this.editDialogVisible = true
      console.log(data)
      for(let item of this.jsonData){ //
        if(item.id === data){
          this.currentPersion = item
        }
      }
      this.$prompt('请输入邮箱', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /[^\s]+/,
        inputErrorMessage: '输入不能为空'
      }).then(({ value }) => {
        this.currentPersion.name = value
        this.request.post("/wang-user", this.currentPersion).then(res => {
          if(res.code === '200'){
            this.load()

          }
        })
      })
    },
    delNode() {

      let flag = false // 先假设没有后代

      this.jsonData.map((item) => {
        console.log(item.pid + "<<>>" + this.currentNode.id)
        if (item.pid === this.currentNode.id) flag = true
      })
      if (this.currentNode.id === 1) {
        this.$alert('根节点，不能删除！', '警告', {
          confirmButtonText: '确定',
        });
      }else if(flag){
        this.$alert('有子节点，不能删除！', '警告', {
          confirmButtonText: '确定',
        });
      }else{
        this.request.delete("/wang-user/" + this.currentNode.id).then(res => {
          if (res.code === '200') {
            this.$message.success("删除成功")
            this.load()
            this.outerVisible = false
          } else {
            this.$message.error("删除失败")
          }
        })
      }
    },

  }
}
</script>

<style scoped>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
  width: calc(100vw - 260px);
  height: calc(100vh - 220px);overflow-y: auto;
  /*position: absolute;*/

}
#app .avat{border-radius: 2em;border-width:2px;}
#app .name{font-weight:700;}
#app .rootNode .name{
  /*background-color: #b3d1eb;*/
  /*border: 3px solid #666666;*/
  color: red;
}

.foot {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #eee;
  padding: 24px;
  overflow: hidden;
  color: #333;
  font-size: 14px;
  text-align: center;
}
.foot a{color:#fff;margin:0 .5em}

.buttonGroup{
  position: absolute;
  left: 20px;
  top: 0;
  text-align: left;
}
/* 定义一个名为 'fadeIn' 的动画 */
@keyframes grow {
  0% {
    transform: scale(.1);
  }
  25% {
    transform: scale(.2);
  }
  50% {
    transform: scale(.8);
  }
  75% {
    transform: scale(1.0);
  }
}
.ele{
  width: 100px;
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-around;*/
  height: 300px;
  background-color: #e56c3c;

  animation: grow 2s infinite alternate;
}

::v-deep .el-dialog {
  position: absolute;
/*// 根据项目中弹窗的大小设定的居中，用其他方式实现居中也行*/
left: calc(50% - 400px);
  top: calc(50% - 242px);
  overflow: hidden;
/*// 设置过渡，出现与消失的动画是靠过渡实现的*/
transition: all 1s;
}
::v-deep .el-dialog__wrapper {
  transition-duration: 0.1s;
}
::v-deep .el-dialog__wrapper.dialog-fade-enter-active,
.el-dialog__wrapper.dialog-fade-leave-active {
  animation: none !important; /*grow 1s infinite alternate!important;*/
}
</style>
